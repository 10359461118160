<template>
  <div class="flex vx-row no-gutter items-center justify-center">
    <div class="vx-col  sm:m-0 m-4">
      <div class="flex justify-center mb-8">
        <img v-if="appName ==='FlipPay'" src="@/assets/images/logo/flippay-logo.svg" width="auto" height="48px" alt="Flippay" class="mx-auto"/>
              <img v-else-if="appName==='RelloPay'" src="@/assets/images/logo/rellopay-logo.svg" width="auto" height="48px" alt="Rellopay" class="mx-auto"/>

      </div>
      <vx-card class="customer-login-card">
        <div class="customer-login-container pt-0 mb-0">
          <h4
            class="mb-0 w-full text-center font-normal lg:text-xl md:text-lg text-sm" style="letter-spacing:-0.5px;color:#666666;background:#F8F8F8;padding:22px 0;"
          >
            Create new password
          </h4>
          <div class="onboard-login-content">
            <label for="password"  class="w-full mb-0 text-base font-light">Password</label>
            <password
              ref="password"
              name="Password"
              v-model="newPassword"
              v-validate="{ required: true, regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/ }"
              :toggle="true"
              :badge="false"
              :showStrengthMeter="false"
            />
            <div class="text-danger text-sm pt-1" v-show="errors.has('Password')">
              {{ errors.first("Password") }}
            </div>

            <label for="password"  class="w-full mb-0 text-base font-light mt-8">Confirm password</label>
            <password
              name="confirm password"
              v-model="confirmPassword"
              v-validate="'confirmed:password'"
              :toggle="true"
              :badge="false"
              :showStrengthMeter="false"
            />
            <div class="text-danger text-sm pt-1" v-show="errors.has('confirm password')">
              {{ errors.first("confirm password") }}
            </div>
          <vs-button
            size="large"
            class="w-full"
            :disabled="isPasswordNotReady"
            @click="validatePassword"
            v-round
            >Save</vs-button
          >
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Password from "vue-password-strength-meter";

export default {
  components: { Password },
  data() {
    return {
      serverUrl: process.env.VUE_APP_API_URL,
      merchantId: "",
      merchant: "",
      newPassword: "",
      confirmPassword: "",
      appName: process.env.VUE_APP_NAME ||"FlipPay",
      logo: require("@/assets/images/logo/flippay-logo.svg"),
    };
  },
  methods: {
    ...mapActions("onboardMerchant", ["resetPassword"]),
    createNewPassword() {
      const payload = {
        newPassword: this.newPassword,
        confirmPassword: this.confirmPassword,
        token: this.$route.params.token
      };
      this.resetPassword(payload)
        .then(res => {
          
          this.$router.push({
            name: "onboarding-login"
          });
        })
        .catch(err => {});
    },
    validatePassword() {
      const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
      const valid = regex.test(this.newPassword);
      
      if (!valid) {
        this.createNewPassword();
      } else {
        this.createNewPassword();
      }
    }
  },

  computed: {
    isPasswordNotReady () {
      let status = true;
      if (
        this.newPassword.length > 0 &&
        this.confirmPassword.length > 0 &&
        !this.errors.has('password') &&
        !this.errors.has('confirm password')
      ) status = false;
      return status;
    }
  }
};
</script>